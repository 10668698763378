<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span class="option_txt">조회기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
            <button
              class="btn_sub2"
              @click="
                fetch_user_action();
                fetch_user_report();
              "
            >
              검색
            </button>
          </div>
        </div>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 3" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>대 메뉴</th>
              <th>서브 메뉴</th>
              <th>실행 횟수</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="action in filteredGroupActions" :key="action.menu_id">
              <td>
                {{ getTopMenuNameFromMenuId(action.menu_group_id) }}
              </td>
              <td>
                {{ getSubMenuNameFromMenuId(action.menu_group_id) }}
              </td>
              <td>
                {{ action.count }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin],
  computed: {
    ...mapGetters({
      date: 'getDateFromUserActionPage',
      group_actions: 'getUserActionGroupByMenuGroupId',
      menuItems: 'getMenuItems',
      users: 'getUsersTempFromUserPage',
      user_actions: 'getUserAction',
      actionType: 'getUserActionType',
      start_date: 'getStartDateFromUserActionPage',
      end_date: 'getEndDateFromUserActionPage',
      user_report: 'getUserReportFromReport',
    }),
    filteredGroupActions() {
      return this.lodash
        .clonedeep(this.group_actions)
        .filter(x => {
          if (x.menu_group_id != 'null') {
            x.menu_group_id == '40'
              ? (x.count = x.count * 11)
              : x.menu_group_id != '41'
              ? (x.count = x.count * 5)
              : x.count;
            return x;
          }
        })
        .sort((a, b) => b.count - a.count);
    },
  },
  methods: {
    ...mapActions(['FETCH_USER_ACTION']),

    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToUserActionPage', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToUserActionPage',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToUserActionPage', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToUserActionPage', yyyymmdd(new Date()));
      }
    },
    async fetch_user_action() {
      try {
        this.showSpinner();
        //사용자 리스트가 없으면
        if (this.users.length == 0) {
          await this.fetch_user_list();
          console.log(this.users);
        }
        if (this.actionType.length == 0) {
          this.showSpinner();
          await this.$store
            .dispatch('FETCH_USER_ACTION_TYPE')
            .then(() => {})
            .catch(() => {
              this.openOneButtonModal(
                '오류',
                '유저 액션 정보 로드 중 오류. 다시 로그인해주십시오.',
              );
              return;
            });
          this.hideSpinner();
        }
        await this.$store
          .dispatch('FETCH_USER_ACTION', this.date)
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '오류',
              '사용현황 데이터를 불러오는 중 오류가 발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        console.log(error);
        this.hideSpinner();
      }
    },
    async fetch_user_report() {
      try {
        this.showSpinner();
        //사용자 리스트가 없으면
        await this.$store
          .dispatch('FETCH_USER_REPORT', this.date)
          .then(() => {})
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '오류',
              '사용현황 데이터를 불러오는 중 오류가 발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        console.log(error);
        this.hideSpinner();
      }
    },
    async fetch_user_list() {
      try {
        this.showSpinner();
        this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '사용자 정보를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
    getTopMenuNameFromMenuId(id) {
      return this.menuItems.find(x => x.sub_menu.find(y => y.id == id)).name;
    },
    getSubMenuNameFromMenuId(id) {
      return this.menuItems
        .find(x => x.sub_menu.find(y => y.id == id))
        .sub_menu.find(x => x.id == id).name;
    },
  },
  created() {
    if (this.start_date == undefined) {
      var date = new Date();
      this.$store.commit('setEndDateToUserActionPage', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToUserActionPage', yyyymmdd(date2));
    }
    if (this.user_actions.length == 0) {
      this.fetch_user_action(this.date);
    }
    if (this.user_report.lenghth == 0) {
      this.fetch_user_report(this.date);
    }
  },
};
</script>

<style scoped></style>
